<template>
  <div class="application-itmes">
    <ValidationObserver ref="AttachmentForm" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
        <b-overlay :show="loading">
          <b-row>
              <b-col sm="12">
                  <div class="group-form-card">
                      <b-card>
                          <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.attachment_info')" label-size="lg"
                              label-class="font-weight-bold pt-0" class="mb-0">
                              <b-row>
                                <!-- nid_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Attested copy of National Identity Card " vid="nid_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="nid_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.nid_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="nid_file"
                                            v-model="formData.nid_file"
                                            @change="onFileChange($event, 'nid_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.nid_file && checkBase64(formData.nid_file) === false" :href="lrcpnServiceBaseUrl  + formData.nid_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- irc_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Copy of IRC (updated)" vid="irc_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="irc_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.irc_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="irc_file"
                                            v-model="formData.irc_file"
                                            @change="onFileChange($event, 'irc_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.irc_file && checkBase64(formData.irc_file) === false" :href="lrcpnServiceBaseUrl  + formData.irc_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- trade_license_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Copy of Trade License (updated)" vid="trade_license_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="trade_license_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.trade_license_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="trade_license_file"
                                            v-model="formData.trade_license_file"
                                            @change="onFileChange($event, 'trade_license_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.trade_license_file && checkBase64(formData.trade_license_file) === false" :href="lrcpnServiceBaseUrl  + formData.trade_license_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- applicant_signature -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Applicant Signature" vid="applicant_signature" :rules="attachValidation">
                                      <b-form-group
                                        label-for="applicant_signature"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.applicant_signature') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="applicant_signature"
                                            v-model="formData.applicant_signature"
                                            @change="onFileChange($event, 'applicant_signature')"
                                            accept=".jpg,.png,.jpeg"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.applicant_signature && checkBase64(formData.applicant_signature) === false" :href="lrcpnServiceBaseUrl  + formData.applicant_signature" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- tin_certificate_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="TIN Certificate (updated)" :rules="attachValidation">
                                      <b-form-group
                                        label-for="tin_certificate_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.tin_certificate_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="tin_certificate_file"
                                            v-model="formData.tin_certificate_file"
                                            @change="onFileChange($event, 'tin_certificate_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.tin_certificate_file && checkBase64(formData.tin_certificate_file) === false" :href="lrcpnServiceBaseUrl  + formData.tin_certificate_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- bin_certificate_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="BIN Certificate (updated)" vid="bin_certificate_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="bin_certificate_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.bin_certificate_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="bin_certificate_file"
                                            v-model="formData.bin_certificate_file"
                                            @change="onFileChange($event, 'bin_certificate_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.bin_certificate_file && checkBase64(formData.bin_certificate_file) === false" :href="lrcpnServiceBaseUrl  + formData.bin_certificate_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- environmental_clearance_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Environmental Update Clearance" vid="environmental_clearance_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="environmental_clearance_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.environmental_clearance_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="environmental_clearance_file"
                                            v-model="formData.environmental_clearance_file"
                                            @change="onFileChange($event, 'environmental_clearance_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.environmental_clearance_file && checkBase64(formData.environmental_clearance_file) === false" :href="lrcpnServiceBaseUrl  + formData.environmental_clearance_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- fire_license_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Fire License (updated)" vid="fire_license_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="fire_license_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.fire_license_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="fire_license_file"
                                            v-model="formData.fire_license_file"
                                            @change="onFileChange($event, 'fire_license_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.fire_license_file && checkBase64(formData.fire_license_file) === false" :href="lrcpnServiceBaseUrl  + formData.fire_license_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- dress_authority_recommended_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Recommendation of the host authority" :rules="attachValidation">
                                      <b-form-group
                                        label-for="dress_authority_recommended_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.dress_authority_recommended_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="dress_authority_recommended_file"
                                            v-model="formData.dress_authority_recommended_file"
                                            @change="onFileChange($event, 'dress_authority_recommended_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.dress_authority_recommended_file && checkBase64(formData.dress_authority_recommended_file) === false" :href="lrcpnServiceBaseUrl  + formData.dress_authority_recommended_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- warehouse_layout_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Warehouse layout" vid="warehouse_layout_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="warehouse_layout_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.warehouse_layout_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="warehouse_layout_file"
                                            v-model="formData.warehouse_layout_file"
                                            @change="onFileChange($event, 'warehouse_layout_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.warehouse_layout_file && checkBase64(formData.warehouse_layout_file) === false" :href="lrcpnServiceBaseUrl  + formData.warehouse_layout_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- drug_control_clearance_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Clearance from Narcotics Control Directorate" vid="drug_control_clearance_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="drug_control_clearance_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.drug_control_clearance_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="drug_control_clearance_file"
                                            v-model="formData.drug_control_clearance_file"
                                            @change="onFileChange($event, 'drug_control_clearance_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.drug_control_clearance_file && checkBase64(formData.drug_control_clearance_file) === false" :href="lrcpnServiceBaseUrl  + formData.drug_control_clearance_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- inspection_report_district_administration_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Acid Sale, Use and Transport License (updated) by District Administration" vid="inspection_report_district_administration_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="inspection_report_district_administration_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.inspection_report_district_administration_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="inspection_report_district_administration_file"
                                            v-model="formData.inspection_report_district_administration_file"
                                            @change="onFileChange($event, 'inspection_report_district_administration_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.inspection_report_district_administration_file && checkBase64(formData.inspection_report_district_administration_file) === false" :href="lrcpnServiceBaseUrl  + formData.inspection_report_district_administration_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                                  <!-- acis_transportation_permission_district_administration_file -->
                                  <b-col sm="6">
                                    <ValidationProvider name="Inspection report (updated) as per schedule prescribed by district administration" vid= "acis_transportation_permission_district_administration_file" :rules="attachValidation">
                                      <b-form-group
                                        label-for="acis_transportation_permission_district_administration_file"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                          {{ $t('lrcpnServicePanel.acis_transportation_permission_district_administration_file') }} <span class="text-danger">*</span>
                                        </template>
                                        <div class="d-flex">
                                            <b-form-file
                                            id="acis_transportation_permission_district_administration_file"
                                            v-model="formData.acis_transportation_permission_district_administration_file"
                                            @change="onFileChange($event, 'acis_transportation_permission_district_administration_file')"
                                            accept=".pdf"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :placeholder="$t('globalTrans.attachment_placeholder')"
                                            :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
                                            ></b-form-file>
                                            <a target="_blank" style="margin-left: 8px;" v-if="formData.acis_transportation_permission_district_administration_file && checkBase64(formData.acis_transportation_permission_district_administration_file) === false" :href="lrcpnServiceBaseUrl  + formData.acis_transportation_permission_district_administration_file" class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i></a>
                                          </div>
                                          <small class="validation_msg"><i :title="$t('teaGardenPanel.attachment_file_1')" class="ri-folder-info-line"></i><b> {{$t('teaGardenPanel.attachment_file_1')}}</b></small>
                                        <div class="invalid-feedback d-block">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </b-col>
                              </b-row>
                          </b-form-group>
                      </b-card>
                  </div>
              </b-col>
          </b-row>
        </b-overlay>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { acidLicenseClearanceAttachmentStoreApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
export default {
props: ['tabFormData', 'draft'],
components: { },
data () {
  return {
    valid: null,
    attachValidation: 'required',
    formData: this.tabFormData,
    lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
    is_edit: false,
    valueOptions: [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 0, text: this.$i18n.locale === 'en' ? 'No' : 'না' }
    ]
  }
},
mounted () {
  this.formData = this.tabFormData
},
computed: {
  loading: function () {
    return this.$store.state.commonObj.loading
  },
  currentLocale () {
    return this.$i18n.locale
  }
},
methods: {
    onFileChange (event, property) {
      const input = event.target
      if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
              this.formData[property] = e.target.result
          }
          reader.readAsDataURL(input.files[0])
      } else {
          this.formData[property] = ''
      }
    },
    checkBase64 (string) {
      var result = ''
      result = string.match('data:')
      if (result) {
          return true
      } else {
          return false
      }
    },
    isNumber (evt) {
        helpers.isNumber(evt)
    },
    async submit () {
      var check = await this.$refs.AttachmentForm.validate()
      if (check) {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const loadingState = { loading: false, listReload: false }
        var result
        const data = Object.assign({}, this.formData, { draft: this.draft })
        result = await RestApi.postData(lrcpnServiceBaseUrl, acidLicenseClearanceAttachmentStoreApi, data)
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          return result
        } else {
          this.$refs.AttachmentForm.setErrors(result.errors)
          this.$toast.error({
            title: this.$t('globalTrans.error'),
            message: this.$t('globalTrans.form_error_msg'),
            color: '#ee5253'
          })
        }
      }
    }
}
}
</script>
