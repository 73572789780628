<template>
    <div class="application-itmes">
        <ValidationObserver ref="applicant_info_form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                <b-overlay :show="loading">
                    <b-row>
                        <b-col sm="12">
                            <div class="group-form-card">
                                <b-card>
                                    <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                                        <b-row>
                                            <!-- search for admin -->
                                            <b-col sm="12" v-if="authUser.user_type === 1">
                                                <div class="row">
                                                    <b-col sm="6">
                                                        <ValidationProvider name="Mobile No" vid="mobile" :rules="'max:11|min:11|numeric'">
                                                            <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="mobile">
                                                                <template v-slot:label>
                                                                    {{ $t('globalTrans.mobile') }}
                                                                </template>
                                                                <b-form-input
                                                                    @keypress="isNumber"
                                                                    type="text"
                                                                    v-model="search.mobile"
                                                                    :state="errors[0] ? false : (valid ? true : null)">
                                                                </b-form-input>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col sm="6">
                                                        <b-button type="button" @click="getExternalUserInfo()" variant="success" class="mt-3 btn-sm">{{ $t('globalTrans.search') }} <b-spinner v-if="isExternalUserLoading" variant="danger" small label="Spinning"></b-spinner></b-button>
                                                    </b-col>
                                                </div>
                                            </b-col>
                                            <!-- applicant_name -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (En)" vid="applicant_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          :disabled="isExternalUserFind"
                                                          v-model="formData.applicant_name"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Name (Bn)" vid="applicant_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="applicant_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.applicant_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                         :disabled="isExternalUserFind"
                                                          v-model="formData.applicant_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_mobile -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Mobile" vid="applicant_mobile" :rules="'max:11|min:11|numeric|required'">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_mobile"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        :disabled="isExternalUserFind"
                                                        v-model="formData.applicant_mobile"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        @keypress="isNumber"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- applicant_email -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Applicant Email" vid="applicant_email" rules="required|email">
                                                    <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="applicant_email"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        v-model="formData.applicant_email"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- company_name -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Company Name (En)" vid="company_name" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="company_name"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.company_name') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.company_name"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- company_name_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Company Name (Bn)" vid="company_name_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="company_name_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.company_name') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                          v-model="formData.company_name_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- office_address -->
                                             <b-col sm="6">
                                                <ValidationProvider name="Office Address (En)" vid="office_address" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="office_address"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.office_address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                          v-model="formData.office_address"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- office_address_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Office Address (Bn)" vid="office_address_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="office_address_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.office_address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                          v-model="formData.office_address_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- warehouse_address -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Warehouse Address (En)" vid="warehouse_address" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="warehouse_address"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.warehouse_address') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                          v-model="formData.warehouse_address"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- warehouse_address_bn -->
                                            <b-col sm="6">
                                                <ValidationProvider name="Warehouse Address (Bn)" vid="warehouse_address_bn" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="warehouse_address_bn"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.warehouse_address') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                          v-model="formData.warehouse_address_bn"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- purpose_of_import -->
                                            <b-col sm="12">
                                                <ValidationProvider name="Purpose Of Import" vid="purpose_of_import" rules="required">
                                                    <b-form-group
                                                      slot-scope="{ valid, errors }"
                                                      label-for="purpose_of_import"
                                                    >
                                                        <template v-slot:label>
                                                            {{ $t('lrcpnServicePanel.purpose_of_import') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                          v-model="formData.purpose_of_import"
                                                          :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-card>
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-form>
        </ValidationObserver>
        <!-- addmore section start -->
        <b-row>
            <b-col sm="12">
                <div class="group-form-card">
                    <b-card>
                        <b-form-group label-cols-lg="3" :label="$t('lrcpnServicePanel.acid_import_info')" label-size="lg"
                            label-class="font-weight-bold pt-0" class="mb-0">
                            <b-row>
                                <ValidationObserver ref="add_more_data" v-slot="{ handaleAddMoreData, reset }">
                                <b-form @submit.prevent="handaleAddMoreData(addMore)" @reset.prevent="reset">
                                    <b-row>
                                        <div>
                                            <!-- add more -->
                                            <b-row>
                                            <!-- acid_id -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Acid Name" vid="acid_id" rules="required|min_value:1">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="acid_id">
                                                    <template v-slot:label>
                                                    {{ $t('lrcpnServicePanel.acid_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="addMoreData.acid_id"
                                                        id="acid_id"
                                                        :options="masterAcidNameList"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- description -->
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Description (En)" vid="description" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="description">
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.description') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                    id="description"
                                                    v-model="addMoreData.description"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                             <!-- description_bn -->
                                             <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                                <b-form-group
                                                    slot-scope="{ valid, errors }"
                                                    label-for="description_bn">
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.description') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                    id="description_bn"
                                                    v-model="addMoreData.description_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="d-flex justify-content-end">
                                            <b-button type="button" v-if="is_edit" variant="danger" class="mr-1 btn-sm" @click="cancelItem()"><i class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}</b-button>
                                            <b-button type="button" @click="addMore" variant="success" class="mr-2 btn-sm"> <i class="ri-file-add-fill"></i> {{ is_edit ? $t('globalTrans.update') : $t('globalTrans.add_more') }}</b-button>
                                        </div>
                                        <hr>
                                        <!-- table data show -->
                                        <b-overlay :show="addMoreLoading">
                                            <b-table-simple style="width: 100%" bordered>
                                                <b-tr>
                                                    <b-th class="text-center" style="width: 15%;"> {{ $t('lrcpnServicePanel.acid_name') }}</b-th>
                                                    <b-th class="text-center" style="width: 65%;"> {{ $t('globalTrans.description') }}</b-th>
                                                    <b-th class="text-center" style="width: 20%;"> {{ $t('globalTrans.action') }}</b-th>
                                                </b-tr>
                                                <template v-if="formData.import_infos.length">
                                                    <slot v-for="(item, index) in formData.import_infos">
                                                        <b-tr :key="index">
                                                            <b-td class="text-center">
                                                                {{ getAcidName(item.acid_id) }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                {{ currentLocale === 'en' ? item.description : item.description_bn }}
                                                            </b-td>
                                                            <b-td class="text-center">
                                                                <b-button @click="editAddMore(index, item)" class="btn btn-sm btn-info"><i class="ri-edit-box-line"></i></b-button>
                                                                <b-button type="button" @click="removeAddMore(index)" class="btn btn- iq-bg-danger btn-sm ml-1" variant="danger"><i class="ri-delete-bin-line m-0"></i></b-button>
                                                            </b-td>
                                                        </b-tr>
                                                    </slot>
                                                </template>
                                                <template v-else>
                                                    <b-tr>
                                                        <b-td colspan="3" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                                    </b-tr>
                                                </template>
                                            </b-table-simple>
                                        </b-overlay>
                                        </div>
                                        <!--  category details end here-->
                                    </b-row>
                                </b-form>
                            </ValidationObserver>
                            </b-row>
                        </b-form-group>
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import RestApi, { lrcpnServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { acidLicenseClearanceApplicantInfoStoreApi, acidLicenseClearanceApplicantInfoUpdateApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import { mapGetters } from 'vuex'
export default {
    props: ['tabFormData', 'fromAdmin'],
    components: {},
    data () {
        return {
            lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
            valid: null,
            formData: {
                import_infos: []
            },
            addMoreData: {
                acid_id: 0
            },
            addMoreLoading: false,
            is_edit: false,
            search: {
                mobile: ''
            },
            isExternalUserLoading: false
        }
    },
    mounted () {
        this.formData = this.tabFormData
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        },
        masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
        }
    },
    methods: {
        async submit () {
            var check = await this.$refs.applicant_info_form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                delete this.formData.business_info
                let result
                const data = Object.assign({}, this.formData, { status: this.status, parent_id: this.parentId })
                if (this.formData.id) {
                  result = await RestApi.putData(lrcpnServiceBaseUrl, acidLicenseClearanceApplicantInfoUpdateApi + '/' + this.formData.id, data)
                } else {
                  result = await RestApi.postData(lrcpnServiceBaseUrl, acidLicenseClearanceApplicantInfoStoreApi, data)
                }
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.formData.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$refs.applicant_info_form.setErrors(result.errors)
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                }
            }
        },
        isNumber (event) {
            helpers.isNumber(event)
        },
        async addMore () {
            this.addMoreLoading = true
            var check = await this.$refs.add_more_data.validate()
            if (check) {
                const existCheck = this.formData.import_infos.find(item => parseInt(item.acid_id) === parseInt(this.addMoreData.acid_id))

                if (existCheck !== undefined) {
                    this.$toast.error({
                        title: 'Warning',
                        message: this.$t('globalTrans.item_exist'),
                        color: '#ee5253'
                    })
                    this.addMoreLoading = false
                    return false
                }

                if (this.is_edit) {
                    this.formData.import_infos.splice(this.addMoreData.index, 1, this.addMoreData)
                } else {
                    this.formData.import_infos.push(this.addMoreData)
                }
                this.addMoreData = {}
                this.$refs.add_more_data.reset()
            }
            this.is_edit = false

            setTimeout(() => {
                this.addMoreLoading = false
            }, 100)
        },
        removeAddMore (index) {
            this.formData.import_infos.splice(index, 1)
        },
        editAddMore (index, item) {
            this.addMoreData = Object.assign({}, item)
            this.is_edit = true
            this.addMoreData.index = index
        },
        cancelItem () {
            this.addMoreData = {}
            this.is_edit = false
            this.$refs.add_more_data.reset()
        },
        getAcidName (id) {
            const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        async getExternalUserInfo () {
            this.isExternalUserLoading = true
            var result
            result = await RestApi.postData(authServiceBaseUrl, 'user-management/user/get-user-details', this.search)
            if (result.success) {
                const data = {
                    applicant_name: result.data.name,
                    applicant_name_bn: result.data.name_bn,
                    applicant_mobile: result.data.mobile,
                    applicant_email: result.data.email,
                    user_id: result.data.id
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = true
            } else {
                const data = {
                    applicant_name: '',
                    applicant_name_bn: '',
                    applicant_mobile: '',
                    applicant_email: '',
                    user_id: null
                }
                this.formData = Object.assign({}, this.formData, data)
                this.isExternalUserFind = false
            }
            this.isExternalUserLoading = false
        }
    }
}
</script>
